import Vue from 'vue';
import axios from "axios";
import VueAxios from 'vue-axios';
import CommonService from '@/service/CommonService';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
  };

// 创建一个Axios实例
const instance = axios.create(config);

// 设置一个变量来保存刷新token的请求是否正在进行中
let isRefreshing = false;

// 创建一个数组来保存因为token过期而被挂起的请求
let refreshSubscribers = [];

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么

    // 如果有token存在，将其添加到请求头中
    const token_type = localStorage.getItem("token_type");
    const access_token = localStorage.getItem("access_token");
    const auth = config.headers['Authorization'];

    if(token_type && access_token){
        if(!auth || (auth.startsWith(token_type) && !auth.endsWith(access_token))){
            config.headers['Authorization'] = token_type + " " + access_token;
        }
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做些什么
    return response;
  },
  function (error) {
    // 如果收到401状态码，表示token过期
    if ((error.response.status === 401 || error.response.status === 424) && (error.response.data.msg === '用户凭证已过期' || error.response.data.msg === 'invalid_grant')) {
      const originalRequest = error.config;

      const isRefreshToken = error.config.url.indexOf('grant_type=refresh_token');
      if(isRefreshToken > 0){
        return error;
      }

      // 如果没有在刷新token，则开始刷新token的流程
      if (!isRefreshing) {
        isRefreshing = true;
            const isLogin =  !localStorage.getItem("access_token") || error.response.data.msg === 'invalid_grant';
            if(isLogin){
               return doLogin(originalRequest);
            }else{
                // 发送请求刷新token
                return CommonService.refreshToken().then((res) => {
                    // 刷新token成功，将新token和refreshToken保存到本地
                    localStorage.setItem('token_type', res.token_type);
                    localStorage.setItem('access_token', res.access_token);
                    localStorage.setItem('refresh_token', res.refresh_token);
 
                    isRefreshing = false;
                    // 更新请求头中的token
                    instance.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;

                    // 执行因为token过期而被挂起的请求
                    refreshSubscribers.forEach((subscriber) => subscriber(res.access_token));
                    refreshSubscribers = [];

                    return instance(originalRequest);
                }).catch((err) => { 
                    // 处理刷新token失败的情况
                 // 清除本地保存的token和refreshToken
                 localStorage.removeItem('token_type');
                 localStorage.removeItem('access_token');
                 localStorage.removeItem('refresh_token');
     
                 // 跳转到登录页面或执行其他相关操作
                 
                 // 刷新token失败，跳转到登录页
                 return doLogin(originalRequest);
                 //Vue.$router.push('/login');
             }).finally(() => {
                 isRefreshing = false;
               });
            }
        
      } else {
        // 如果正在刷新token，则将当前请求添加到刷新队列中
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(instance(originalRequest));
          });
        });
      }
    }


    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

const doLogin = (originalRequest) =>{
    return CommonService.login("guest","123456").then((res) => {
        console.log(res)
        console.log(33444)
        //
        isRefreshing = false;
        // 更新请求头中的token
        instance.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;


        // 执行因为token过期而被挂起的请求
        refreshSubscribers.forEach((subscriber) => subscriber(res.access_token));
        refreshSubscribers = [];

        return instance(originalRequest);
    }).finally(() => {
        isRefreshing = false;
      });
}

Vue.use(VueAxios,instance);

// 导出Axios实例
export default instance;
